import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import mapObject from 'map-obj';
import { translations } from './translations';
import languages_accounts_production from './languages-accounts-production.json';

export const languagePropName = 'hl';
export const languageCookiePropName = 'accounts.hl';

export const I18nKey = {
  Allow: 'allow',
  AlreadyHaveAnAccount: 'already_have_an_account',
  AuthorizationError: 'authorization_error',
  Cancel: 'cancel',
  ChooseWorkspaceTitle: 'choose_workspace_title',
  ChooseYourDisplayName: 'choose_your_display_name',
  Confirm: 'confirm',
  ConfirmAccountLink: 'confirm_account_link',
  ConfirmAccountLinkText: 'confirm_account_link_text_3',
  Confirmation: 'confirmation',
  ContactUs: 'contact_us',
  Continue: 'continue',
  ContinueWithFacebook: 'continue_with_facebook',
  ContinueWithApple: 'continue_with_apple',
  ContinueWithGoogle: 'continue_with_google',
  ContributorCreateAccountTermsOfService: 'contributor_create_account_terms_of_service',
  Copyright: 'copyright',
  CreateAccountPrompt: 'create_account_prompt',
  CreateAccountSubmit: 'create_account_submit',
  CreateAccountTermsOfService: 'create_account_terms_of_service_2',
  CreateAnAccount: 'create_an_account',
  CreateYourAccount: 'create_your_account',
  DownloadForFree: 'download_for_free',
  RequestDemo: 'request_demo',
  RequestDemoPrompt: 'request_demo_prompt',
  CreateYourFreeAccount: 'create_your_free_account',
  CreateYourPremierAccountWithoutInvite: 'create_your_premier_account_without_invite',
  CreateYourPremierAccount: 'create_your_premier_account',
  CurrentlySignedInAs: 'currently_signed_in_as_2',
  DeleteAccount: 'delete_account',
  DisplayNameLabel: 'display_name_label',
  DisplayNameTooltip: 'display_name_tooltip',
  Done: 'done',
  EmailAddress: 'reset_password_form.email_address_field_label',
  FirstName: 'first_name',
  FullNameLabel: 'full_name_label',
  UsernameOrEmailAddress: 'username_email_address_field_label',
  Error: 'error',
  ErrorInternalServerError: 'error_internal_server_error',
  ErrorInvalidEmail: 'invalid_email',
  ErrorMissingRequiredField: 'required',
  ErrorPasswordMinLength: 'short_password',
  FAQ: 'faq',
  ForgotPassword: 'forgot_password',
  GetStandardContentTitle: 'get_standard_content_title',
  GetStarted: 'get_started',
  GoBack: 'go_back',
  GetThisImageAndMore: 'get_this_image_and_more',
  HaveAnyQuestions: 'have_any_questions',
  LastName: 'last_name',
  LicenseAgreement: 'license_agreement',
  LicensingTermsOfServiceTitle: 'licensing_terms_of_service_title',
  LogIn: 'log_in',
  LoginPlaceholder: 'login_placeholder',
  LoginUpdate: 'login_update',
  LoginWithShutterstock: 'login_with_shutterstock',
  Next: 'next',
  NoThanks: 'no_thanks',
  NotVerified: 'not_verified',
  NotYou: 'not_you',
  OAuthApplicationPermissions: 'oauth_application_permissions',
  OAuthApplicationPermissionsGranted: 'oauth_application_permissions_granted',
  OAuthClientAskingPermission: 'oauth_client_asking_permission',
  OptionOr: 'option_or',
  Password: 'password',
  PleaseAgreeToTosShort: 'please_agree_to_tos_short',
  PrivacyPolicy: 'privacy_policy',
  PrivacyPolicyTitle: 'footer_link_privacy_policy',
  ProvideYourLegalName: 'provide_your_legal_name',
  RemoveUserConfirm: 'remove_user_confirm',
  RemoveUserConfirmCancelBtn: 'remove_user_confirm_cancel_btn',
  RemoveUserConfirmError: 'remove_user_confirm_error',
  RemoveUserConfirmPassword: 'remove_user_confirm_password',
  RemoveUserConfirmTitle: 'remove_user_confirm_title',
  RemoveUserDeleteBtn: 'remove_user_delete_btn',
  RemoveUserFinalConfirmationHelp: 'remove_user_final_confirmation_help',
  RemoveUserFinalConfirmationTitle: 'remove_user_final_confirmation_title',
  RemoveUserFormHelp: 'remove_user_form_help',
  RemoveUserWelcomeTitle: 'remove_user_welcome_title',
  RemoveIneligibleUserAdmin: 'remove_ineligible_user_admin_eligible',
  RemoveIneligibleUserInternalError: 'remove_ineligible_user_internal_error',
  RemoveIneligibleUserSubscriptions: 'remove_ineligible_user_form_help_subscriptions',
  RemoveIneligibleUserUserNotAuthorized: 'remove_ineligible_user_user_not_authorized',
  ResetPasswordFormHelp: 'reset_password_form.help',
  ShowMe: 'show_me',
  ShutterstockEmailOffers: 'ss_email_offer',
  SupportCenter: 'support_center',
  SwitchAccount: 'switch_account',
  Undo: 'undo',
  UnknownIdentityProviderForUser: 'unknown_identity_provider_for_user',
  UnsupportedBrowser: 'unsupported_browser',
  UserIsDisabledText: 'user_is_disabled_text',
  UserIsSuspendedText: 'user_is_suspended_text',
  UsernameExists: 'username_exists',
  ValuePropsWwwDownloadComps: 'value_props_www_download_comps',
  ValuePropsWwwMuchMore: 'value_props_www_much_more',
  ValuePropsWwwHistory: 'value_props_www_history',
  ValuePropsWwwSaveAssets: 'value_props_www_save_assets',
  ValuePropsWwwShareCollections: 'value_props_www_share_collections',
  ValuePropsWwwTitle: 'value_props_www_title',
  ValueUserFullNameMaxLength: 'val_user_full_name_max_length',
  ValueUserUsernameRequired: 'val_user_username_required',
  ValueUserDisplayNameRequired: 'val_user_display_name_required',
  ValueUserDisplayNameMaxLength: 'val_user_display_name_max_length',
  ValueUserDisplayNameNotFormatted: 'val_user_display_name_not_formatted',
  VerifyAccount: 'verify_account',
  Verified: 'verified',
  WebsiteTermsOfServiceTitle: 'website_terms_of_service_title',
  WentWrong: 'went_wrong',
  WhatAreWorkspaces: 'what_are_workspaces',
  WrongPassword: 'wrong_password',
  WrongPerm: 'wrong_perm',
  YourAccounts: 'your_accounts',
  YoureAllSet: 'youre_all_set',
  LoginWithPassword: 'login_with_password',
  LoginInWithSsoTitle: 'login_in_with_sso_title',
  LoginInWithSsoLink: 'login_in_with_sso_link',
  samlInvalidRedundantText: 'saml_invalid_redundant_text',
  samlInvalidSignature: 'saml_invalid_signature',
  samlInvalidCertificate: 'saml_invalid_certificate',
  samlInvalidPemNoStartLine: 'saml_invalid_pem_no_start_line',
  samlEmailNotProvider: 'saml_email_not_provided',
  samlEmailInvalid: 'saml_email_invalid',
  samlFullnameNotProvided: 'saml_fullname_not_provided',
  samlMultipleTeamsProvided: 'saml_multiple_teams_provided',
  samlUnknownError: 'saml_unknown_error',
  samlErrorHelpText: 'saml_error_help_text',
  samlUnsuccessfulLogin: 'saml_unsuccessful_login',
  Send: 'send',
  NotFoundTitle: 'not_found_title',
  NotFoundDesc: 'not_found_desc',
  ErrorCode: 'error_code',
  EmailSent: 'email_sent',
  ForgotPasswordSentHelp: 'forgot_password_sent_help',
  Refresh: 'refresh',
  ChooseWorkspace: 'choose_workspace',
  ChooseWorkspaceHelp: 'choose_workspace_help',
  ChooseWorkspaceFetchError: 'choose_workspace_fetch_error',
  WorkspaceTypeTeam: 'workspace_type_team',
  WorkspaceTypeUnknown: 'workspace_type_unknown',
  WorkspaceTypePremier: 'workspace_type_premier',
  WorkspaceTypeIndividual: 'workspace_type_individual',
  LearnMore: 'learn_more',
  OfferCreateUserSubtitleP31193: 'offer_create_user_subtitle_p31193',
  OtpHelp: 'otp_help',
  OtpHelp2: 'otp_help2',
  OtpResendCodeHelp: 'otp_resend_code_help',
  OtpLogOut: 'otp_log_out',
  OtpVerify: 'otp_verify',
  OtpEnterCode: 'otp_enter_code',
  OtpEnterVerificationCode: 'otp_enter_verification_code',
  OtpTriggerVerifyEmailTitle: 'otp_trigger_verify_email_title',
  OtpTriggerVerifyEmailHelp: 'otp_trigger_verify_email_help',
  OtpTriggerVerifyEmailVerifyButton: 'otp_trigger_verify_email_verify_button',
  OtpError: 'otp_error',
  OtpResent: 'otp_resent',
  OtpMaxVerificationTriggerAttemptsError: 'otp_max_verification_trigger_attempts_error',
  OtpVerificationCheckAttemptsError: 'otp_verification_check_attempts_error',
  OtpVerificationCheckExpiredError: 'otp_verification_check_expired_error',
  OtpVerificationCheckDeviceError: 'otp_verification_check_device_error',
  OtpVerificationCheckCodeError: 'otp_verification_check_code_error',
  OtpVerificationTriggerFailedError: 'otp_verification_trigger_failed_error',
  userId: 'user_id',
  SendResetLink: 'send_reset_link',
  orgId: 'org_id',
  workspaceId: 'workspace_id',
  ForgotYourPasswordTitle: 'forgot_your_password_title',
  SsoLoginUserNotfound: 'sso_login_user_not_found',
  CreateCreateUserValuePropsTitle: 'create_create_user_value_props_title',
  CreateCreateUserValuePropsBulletEdit: 'create_create_user_value_props_bullet_edit',
  CreateCreateUserValuePropsBulletAi: 'create_create_user_value_props_bullet_ai',
  CreateCreateUserValuePropsBulletLibrary: 'create_create_user_value_props_bullet_library',
  CreateCreateUserValuePropsBulletTools: 'create_create_user_value_props_bullet_tools',
  CreateCreateUserValuePropsBulletPlan: 'create_create_user_value_props_bullet_plan',
  AndSoMuchMore: 'and_so_much_more',
  ImageNewUserTitleNew: 'image_new_user_title_new',
  ImageNewUserTitle: 'image_new_user_title',
  ImageNewUserTitle7DayTrial: 'image_new_user_title_7_day_trial',
  ImageNewUserTitle15DayTrial: 'image_new_user_title_15_day_trial',
  ImageCreateUserGreetingBulletRiskFree: 'image_create_user_greeting_bullet_risk_free',
  ImageCreateUserGreetingBulletRiskFree7Days: 'risk_free_7_day_trial',
  ImageCreateUserGreetingBulletRiskFree15Days: 'risk_free_15_day_trial',
  ImageCreateUserGreetingBulletKeepNew: 'image_create_user_greeting_bullet_keep_new',
  ImageCreateUserGreetingBulletKeepNewP31193: 'image_create_user_greeting_bullet_keep_new_p31193',
  ImageCreateUserGreetingBulletKeep: 'image_create_user_greeting_bullet_keep',
  ImageCreateUserGreetingBulletSaveNew: 'image_create_user_greeting_bullet_save_new',
  ImageCreateUserGreetingBulletSave: 'image_create_user_greeting_bullet_save',
  ImageCreateUserGreetingBulletSave7Days: 'image_create_user_greeting_bullet_save_7days',
  ImageCreateUserGreetingBulletSave15Days: 'image_create_user_greeting_bullet_save_15days',
  ImageCreateUserGreetingBulletFullNew: 'image_create_user_greeting_bullet_full_new',
  ImageCreateUserGreetingBulletFull: 'image_create_user_greeting_bullet_full',
  ImageCreateUserLegalNote: 'image_create_user_legal_note',
  ImageCreateUserGreetingBulletAIPS: 'image_create_user_greeting_bullet_aips',
  IndiaNoFreeTrialMessage: 'india_gtm_no_free_trial_message',
  ImageCreateUserTryForFree: 'image_create_user_try_for_free',
  TryShutterstockForFree: 'try_shutterstock_for_free',
  SignUp: 'sign_up',
  SignUpStartFreeTrial: 'sign_up_start_free_trial',
  StartFreeTrial: 'start_free_trial',
  BuyAndDownload: 'buy_and_download',
  SubscribeAndDownload: 'subscribe_and_download',
  ResetPasswordTitle: 'reset_password_title',
  ResetPasswordHelp: 'reset_password_help',
  ResetPasswordSubmit: 'reset_password_submit',
  ResetPasswordNew: 'reset_password_submit',
  ResetPasswordConfirm: 'reset_password_confirm',
  ResetPasswordForgot: 'reset_password_forgot',
  ResetPasswordWentWrong: 'reset_password_went_wrong',
  ResetPasswordUnableVerify: 'reset_password_unable_verify',
  ResetPasswordAlreadyUsed: 'reset_password_already_used',
  ResetPasswordDifferent: 'reset_password_different',
  ResetPasswordSame: 'reset_password_same',
  PasswordDoesntMatchConfirm: 'password_doesnt_match_confirm',
  Email: 'email',
  CreatePassword: 'create_password',
  AccessAccount: 'access_account',
  ResetPasswordRequired: 'reset_password_required',
  ResetPasswordSSO: 'reset_password_sso',
  ResetPasswordUnusualActivity: 'reset_password_unusual_activity',
  ResetPasswordExpired: 'reset_password_expired',
  CreateUserFormTrialFlex10Title: 'create_user_form_trial_flex10_title',
  CreateUserTrialFlex10AIPSTitle: 'create_user_trial_flex10_aips_title',
  CreateUserTrialFlex10Title: 'create_user_trial_flex10_title',
  CreateUserTrialFlex10GetImagesMusicTitle: 'create_user_trial_flex10_get_images_music_title',
  CreateUserTrialFlex10LegalNote: 'create_user_trial_flex10_legal_note',
  ChangeCredentialsTitle: 'change_credentials_title',
  ChangeCredentialsCurrentPassword: 'change_credentials_current_password',
  ChangeCredentialsNewPassword: 'change_credentials_new_password',
  ChangeCredentialsNewConfirmPassword: 'change_credentials_new_confirm_password',
  ChangeCredentialsPasswordsHelp: 'change_credentials_password_help',
  ChangeCredentialsWrongPasswordError: 'change_credentials_error_wrong_password_error',
  ChangeCredentialsUpdated: 'change_credentials_updated',
  ChangeCredentialsPasswordNotsameError: 'change_credentials_password_notsame_error',
  ChangeEmailNotSameError: 'change_email_password_not_same_error',
  ChangeEmailAddress: 'change_email_address',
  NewEmail: 'new_email',
  ConfirmEmail: 'confirm_new_email',
  SaveChange: 'save_change',
  EmailExists: 'email_exists',
  RequestedEmailVerificationCheckInbox: 'requested_email_verification_check_inbox',
  UserVerificationsSentDesc: 'user_verifications_sent_desc',
  UserVerificationsSentEmail: 'user_verifications_sent_email',
  UserVerificationsSentResend: 'user_verifications_sent_resend',
  UserVerificationsCodeTitle: 'user_verifications_code_title',
  UserVerificationsCodeSuccess: 'user_verifications_code_success',
  EmailVerificationLinkExpired: 'email_verification_link_expired',
  UserVerificationsCodeExpiredResendEmail: 'user_verifications_code_expired_resend_email',
  UsernamePasswordMatch: 'username_password_match',
  NeedEmailChange: 'need_email_change',
  ShutterstockLogo: 'shutterstock_logo',
  ShowPassword: 'show_password',
  HidePassword: 'hide_password',
  UpdateEmailFormTitle: 'update_email_title',
  UpdateEmailFormCurrentEmailMessage: 'update_email_current_email_message',
  UpdateEmailFormNewEmailMessage: 'update_email_new_email_message',
  UpdateEmailFormNewEmailLabel: 'update_email_new_email_label',
  UpdateEmailSuccessMessage: 'update_email_success_message',
};

export const Language = {};
languages_accounts_production.forEach((entry) => {
  Language[entry.english_name] = entry;
});

export const supportedLanguages = Object.values(Language);

export const getLanguage = (code) =>
  supportedLanguages.filter((language) => language.code === code)[0] || Language.English;

export const Country = {
  Canada: {
    code: 'CA',
  },
  France: {
    code: 'FR',
  },
  Italy: {
    code: 'IT',
  },
  UnitedStates: {
    code: 'US',
  },
};

let resources = translations;
if (process.env.NODE_ENV === 'development' && localStorage.getItem('use_translations') === null) {
  // For development, prefix every non-English string with "[FPO:<code>]"
  // to make it easier to visually check that the component template is
  // internationalized.
  supportedLanguages
    .filter(({ code }) => code !== 'en')
    .forEach(({ code }) => {
      const mapper = (key, value) => [
        key,
        typeof value === 'string' ? `[FPO:${code}] ${value}` : mapObject(value, mapper),
      ];

      resources = {
        ...resources,
        [code]: mapObject(resources.en, mapper),
      };
    });
}

export const i18nInit = async ({ language = 'en' } = {}) => {
  const t = await i18n.use(initReactI18next).init({
    debug: !!localStorage.getItem('debug'),
    resources,
    lng: language,
    fallbackLng: 'en',
    // Use keys in the form <component>[.<component>...].<key>,
    // e.g. reset_password_form.title, card.header.subtitle
    keySeparator: '.',
    interpolation: {
      // Disable XSS protection, which is already handled by React
      escapeValue: false,
    },
  });

  return { i18n, t };
};
