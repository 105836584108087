import { useState } from 'react';
import { Box } from '@mui/material';
import { OtpForm } from './OtpForm';
import { I18nKey } from '../../i18n';
import { decodeHtmlCharRef } from '../../utils/html';

export const RestApiOtpForm = ({
  error = null,
  footer,
  titleText,
  submitText,
  api,
  onCheckSuccess,
  webPropertyEmail = '',
  verificationNextUrl = null,
  showOtpLogoutView = false,
}) => {
  const [alert, setAlert] = useState(error);

  const handleCodeCheck = (code) => {
    return api.checkOtp({ code });
  };

  const handleResend = () => {
    return api.resendOtp();
  };

  const handleResendSuccess = () => {
    setAlert({ i18nKey: I18nKey.OtpResent, level: 'success' });
  };

  const handleFailure = (err) => {
    let error = null;
    if (err.error) {
      if (err.error.text) err.error.text = decodeHtmlCharRef(err.error.text);
      error = err.error;
    }
    setAlert(error);
  };

  return (
    <Box>
      <OtpForm
        onCheckCode={handleCodeCheck}
        onCheckCodeSuccess={onCheckSuccess}
        onCheckCodeFailure={handleFailure}
        onResendCode={handleResend}
        onResendCodeSuccess={handleResendSuccess}
        onResendCodeFailure={handleFailure}
        webPropertyEmail={webPropertyEmail}
        verificationNextUrl={verificationNextUrl}
        showOtpLogoutView={showOtpLogoutView}
        footer={footer}
        titleText={titleText}
        submitText={submitText}
        alert={alert}
      />
    </Box>
  );
};
