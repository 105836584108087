import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { I18nKey } from '../../i18n';
import { Form, FormAction, FormHeader, FormHelpText, FormTitle, FormBody } from '../../core-components';
import classes from './OtpForm.module.scss';
import { OtpHelp } from './OtpHelp';
import { OtpResendLinkHelp } from './OtpResendLinkHelp';
import { OtpLogOutLinkHelp } from './OtpLogOutLinkHelp';
import { TextDivider } from '../../core-components';
import { AnalyticsTracker } from '../../analytics/trackers';
import { OverlayLoader } from '../../core-components/OverlayLoader/OverlayLoader';

export const OtpStatus = {
  Resending: 'resending',
  Resent: 'resent',
  Checking: 'checking',
  Checked: 'checked',
};

export const OtpForm = ({
  alert,
  footer,
  titleText,
  submitText,
  onCheckCode = () => {},
  onCheckCodeSuccess = () => {},
  onCheckCodeFailure = () => {},
  onResendCode = () => {},
  onResendCodeSuccess = () => {},
  onResendCodeFailure = () => {},
  webPropertyEmail = '',
  verificationNextUrl = null,
  showOtpLogoutView = false,
}) => {
  const { t: translate } = useTranslation();

  const getInitialOtp = (alert) => {
    const isFieldError = alert && alert.isField;
    return {
      status: null,
      formAlert: !isFieldError ? alert : null,
      fieldAlert: isFieldError ? alert : null,
    };
  };

  const initialFormData = {
    code: '',
    valid: false,
  };
  const initialOtp = getInitialOtp(alert);

  const [canResend, setCanResend] = useState(true);
  const [otp, setOtp] = useState(initialOtp);
  const [formData, setFormData] = useState(initialFormData);

  const { code } = formData;
  const isSubmitEnabled = () => {
    if (otp.status === OtpStatus.Checking) {
      return false;
    }
    return !!formData.code;
  };

  useEffect(() => {
    if (alert) {
      setOtp(getInitialOtp(alert));
    }
  }, [alert]);

  // Code Check Handlers
  const checkOtp = () => {
    setOtp({ ...otp, status: OtpStatus.Checking });

    return onCheckCode(code)
      .then(onCheckCodeSuccess)
      .catch((error) => {
        setOtp({ ...otp, status: null });
        return onCheckCodeFailure(error);
      });
  };

  // Code Resend Handlers
  const resendOtp = () => {
    setOtp({ ...otp, status: OtpStatus.Resending });

    return onResendCode()
      .then(handleResendOtpSuccess)
      .catch((error) => {
        setOtp({ ...otp, status: null });
        return onResendCodeFailure(error);
      });
  };

  const handleResendOtpSuccess = (response) => {
    setOtp({
      ...otp,
      status: OtpStatus.Resent,
      formAlert: { i18nKey: I18nKey.OtpResent, level: 'success' },
      fieldAlert: null,
    });
    setCanResend(false);
    onResendCodeSuccess(response);

    setTimeout(() => {
      setCanResend(true);
    }, 10000);
  };

  // UI Handlers
  const handleCodeChange = (code) => {
    const newFormData = {
      ...formData,
      code,
    };
    setFormData(newFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    checkOtp();
  };

  const handleResendClick = (event) => {
    event.preventDefault();
    resendOtp();
  };

  let formAlerts = otp.formAlert ? (
    <Alert severity={otp.formAlert.level}>
      {otp.formAlert.i18nKey ? translate(otp.formAlert.i18nKey) : otp.formAlert.text}
    </Alert>
  ) : null;

  let fieldAlert = otp.fieldAlert
    ? otp.fieldAlert.i18nKey
      ? translate(otp.fieldAlert.i18nKey)
      : otp.fieldAlert.text
    : null;

  return (
    <Form onSubmit={handleSubmit} noValidate={true} data-test-id={OtpForm.TestSelector.Form}>
      <div className={classes.formInnerWrapper}>
        {otp.status === OtpStatus.Resending && (
          <div className={classes.overlayLoaderWrapper}>
            <OverlayLoader />
          </div>
        )}
        <FormHeader>
          <FormTitle>{titleText || translate(I18nKey.OtpEnterVerificationCode)}</FormTitle>
          <FormHelpText>
            <OtpHelp webPropertyEmail={webPropertyEmail} />
          </FormHelpText>
        </FormHeader>
        <FormBody>
          {formAlerts && <div className={classes.alertWrapper}>{formAlerts}</div>}
          <AnalyticsTracker.OnClick eventLabel="passcode-input-click" pageSection="body">
            <TextField
              data-test-id={OtpForm.TestSelector.Code}
              label={translate(I18nKey.OtpEnterCode)}
              value={code}
              error={!!fieldAlert}
              helperText={fieldAlert}
              fullWidth
              onChange={(event) => handleCodeChange(event.target.value)}
            />
          </AnalyticsTracker.OnClick>
          <FormAction>
            <AnalyticsTracker.OnClick eventLabel="passcode-verify-click" pageSection="body">
              <div className={classes.bottomSpacingMd}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!isSubmitEnabled()}
                  data-test-id={OtpForm.TestSelector.Submit}>
                  {submitText || translate(I18nKey.OtpVerify)}
                </Button>
              </div>
            </AnalyticsTracker.OnClick>
          </FormAction>
          <AnalyticsTracker.OnClick eventLabel="passcode-resend-click" pageSection="body">
            <OtpResendLinkHelp onClick={handleResendClick} disabled={!canResend} />
          </AnalyticsTracker.OnClick>
          {showOtpLogoutView ? (
            <TextDivider>
              <b>{translate(I18nKey.OptionOr)}</b>
            </TextDivider>
          ) : null}
          {showOtpLogoutView ? (
            <AnalyticsTracker.OnClick eventLabel="passcode-logout-click" pageSection="body">
              <OtpLogOutLinkHelp nextUrl={verificationNextUrl} />
            </AnalyticsTracker.OnClick>
          ) : null}
          {footer}
        </FormBody>
      </div>
    </Form>
  );
};

OtpForm.TestSelector = {
  Form: 'otp-form',
  Submit: 'otp-form-submit-button',
  Code: 'otp-code-input',
};
